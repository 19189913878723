import React, { Component } from 'react';

import './KaraokeMap.css';

export default class KaraokeMap extends Component {
    render() {
        return (
            <div className="Ref kc1">
                <img src="https://i.imgur.com/TSZzjgW.png" alt="karaoke map"/>
                <p className="kc4"><span>Reference as </span><span className="kc5">karaoke</span></p>
                <p className="kc3"><span className="kc0"></span></p>
                <ol className="kc6 lst-kix_fqiuaggjru1i-0 start" start="1">
                    <li className="kc2"><span className="kc0">SEAT1</span></li>
                    <li className="kc2"><span className="kc0">SEAT2</span></li>
                    <li className="kc2"><span className="kc0">SEAT3</span></li>
                    <li className="kc2"><span className="kc0">SEAT4</span></li>
                    <li className="kc2"><span className="kc0">SEAT5</span></li>
                    <li className="kc2"><span className="kc0">SEAT6</span></li>
                    <li className="kc2"><span className="kc0">onTable</span></li>
                    <li className="kc2"><span>screen</span></li>
                </ol>
            </div>
        );
    }
}