import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export default class Cloud {
    constructor() {
        this.app = firebase.initializeApp(config);
        this.auth = this.app.auth();
        this.provider = new firebase.auth.GoogleAuthProvider();
        this.database = firebase.database();
        this.currentUser = null;
    }

    /* BASIC OPERATIONS */

    read = (path, callback) => {
        this.database.ref(path).once('value').then(snapshot => {
            callback(snapshot.exists(), snapshot.val());
        });
    }

    write = (path, data) => this.database.ref(path).set(data);

    exists = (path, key, equalTo, callback) => {
        this.database.ref(path).orderByChild(key).equalTo(equalTo).once('value').then(snapshot => {
            callback(snapshot.exists());
        });
    }

    /* AUTHENTICATION */

    login = () => {
        if (!!this.currentUser) return;
        this.auth.signInWithRedirect(this.provider);
    };

    didLoginViaRedirect = callback => {
        const cloud = this;
        this.auth.getRedirectResult().then(result => {
            var user = result.user;
            if (!user) {
                callback(false, false);
                return;
            }
            // TODO: exists function isn't working!
            cloud.exists('users', 'email', user.email, exists => {
                if (exists) {
                    // returning user
                    cloud.currentUser = { id: user.uid, email: user.email };
                    callback(true, false);
                    this.read('users/' + user.uid, (success, data) => {
                        if (success)
                            for (var key in data) cloud.currentUser[key] = data[key];
                    });
                } else {
                    var email = user.email;
                    var name = email.substring(0, email.indexOf('@'));
                    cloud.currentUser = { id: user.uid, email: email, username: name };
                    this.write('users/' + user.uid, { email: email, username: name });
                    callback(true, true);
                }
            });
        }).catch(error => {
            console.log(error.code + ' ' + error.message);
            callback(false, false);
        });
    }

    logout = (callback) => {
        const cloud = this;
        this.auth.signOut().then(() => {
            cloud.currentUser = null;
            callback(true);
        }).catch((error) => {
            console.log(error.code + ' ' + error.message);
            callback(false);
        });
    }

    /*listenToAuthState = callback => {
        return this.auth.onAuthStateChanged(user => {
            if (!user) {
                this.currentUser = null;
                callback(null);
            } else if (!this.currentUser || user.uid !== this.currentUser.uid) {
                this.currentUser = { id: user.uid, email: user.email };
                this.read('users/' + user.uid, (success, data) => {
                    if (success) this.currentUser = data;
                    callback(this.currentUser);
                })
            }
        });
    }*/

    /* WORKS */

    getWorks = callback => {
        this.database.ref('dialogues').orderByChild('author').equalTo(this.currentUser.id).once('value').then(snapshot => {
            if (snapshot.exists()) {
                // in the format { id1: data, id2: data . . . }
                var unflattened = snapshot.val();
                var works = [];
                for (var id in unflattened) {
                    var work = unflattened[id];
                    work.id = id;
                    works.push(work);
                }
                callback(works);
            }
        });
    }

    saveDialogue = (title, raw, callback) => {
        var ref = this.database.ref('dialogues').push();
        ref.set({
            title: title,
            author: !!this.currentUser ? this.currentUser.id : "guest",
            raw: raw
        });
        callback(ref.key);
    }

    overwriteDialogue = (id, title, raw, callback) => {
        var update = {};
        update['dialogues/' + id + '/title'] = title;
        update['dialogues/' + id + '/raw'] = raw;
        this.database.ref().update(update, error => {
            if (error) callback(false);
            else callback(true);
        });
    }

    deleteDialogue = id => {
        this.write('dialogues/' + id, null);
    }
}