import React from 'react';

const Context = React.createContext(null);

export const withCloud = Component => props => (
    <Context.Consumer>
      {cloud => <Component {...props} cloud={cloud} />}
    </Context.Consumer>
);

export default Context;