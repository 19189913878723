import React, { Component } from 'react';

import './CoffeeshopMap.css';

export default class CoffeeshopMap extends Component {
    render() {
        return (
            <div className="Ref csc5">
                <img src="https://i.imgur.com/EkRwy6x.png" alt="coffeeshop map"/>
                <p className="csc7"><span>Reference as </span><span className="csc2">coffeeshop</span><span>&nbsp;/ </span><span className="csc2">coffeeshopNight</span><span>&nbsp;(nighttime) / </span><span className="csc2">coffeeshopEve</span><span className="csc1">&nbsp;(evening).</span></p>
                <p className="csc3"><span className="csc1"></span></p>
                <ol className="csc6 lst-kix_2zd3u997csu0-0 start" start="1">
                    <li className="csc0"><span className="csc1">SEAT1</span></li>
                    <li className="csc0"><span className="csc1">SEAT2</span></li>
                    <li className="csc0"><span className="csc1">SEAT3</span></li>
                    <li className="csc0"><span className="csc1">SEAT4</span></li>
                    <li className="csc0"><span className="csc1">SEAT5</span></li>
                    <li className="csc0"><span className="csc1">SEAT6</span></li>
                    <li className="csc0"><span className="csc1">table1</span></li>
                    <li className="csc0"><span className="csc1">table2</span></li>
                    <li className="csc0"><span className="csc1">table3</span></li>
                    <li className="csc0"><span className="csc1">door</span></li>
                    <li className="csc0"><span className="csc1">window1</span></li>
                    <li className="csc0"><span className="csc1">window2</span></li>
                    <li className="csc0"><span className="csc1">window3</span></li>
                    <li className="csc0"><span className="csc1">table4</span></li>
                    <li className="csc0"><span className="csc1">table5</span></li>
                    <li className="csc0"><span className="csc1">cashRegister</span></li>
                    <li className="csc0"><span className="csc1">sink</span></li>
                    <li className="csc0"><span className="csc1">counter1</span></li>
                    <li className="csc0"><span className="csc1">counter2</span></li>
                    <li className="csc0"><span className="csc1">counter3</span></li>
                    <li className="csc0"><span className="csc1">counter4</span></li>
                    <li className="csc0"><span className="csc1">sign1</span></li>
                    <li className="csc0"><span className="csc1">sign2</span></li>
                    <li className="csc0"><span className="csc1">couch1</span></li>
                    <li className="csc0"><span className="csc1">couch2</span></li>
                    <li className="csc0"><span className="csc1">couch3</span></li>
                    <li className="csc0"><span className="csc1">couch4</span></li>
                    <li className="csc0"><span>couch5</span></li>
                </ol>
            </div>
        );
    }
}