import React, { Component } from 'react';
import { withCloud } from './Context';

import { validate } from './Parser';

class EditDialogue extends Component {
	constructor(props) {
		super(props);
		this.shouldPrefill = !!props.prefilled;
		if (this.shouldPrefill) {
			this.state = { 
				id: props.prefilled.id,
				title: props.prefilled.title, 
				raw: props.prefilled.raw 
			};
		} else {
			this.state = { title: "", raw: "" }
		}
		this.cloud = props.cloud;
	}

	handleTextChange = (event) => {
		this.setState({ raw: event.target.value });
	}

	check = (event) => {
		event.preventDefault();
        try {
            var raw = this.state.raw;
            if (!raw) throw new Error("Can't be blank!");
			var title = validate(raw);
			//this.onCheck(title, raw);
			this.setState({ title: title, raw: raw });
			alert('Looks good!');
        } catch(error) {
            alert(error.message);
        }
	}

	save = (event) => {
		event.preventDefault();
		if (this.state.raw.length === 0) {
			alert("Please check your dialogue first!");
		} else {
			if (!!this.state.id) {
				// editing existing work, so overwrite
				this.cloud.overwriteDialogue(
					this.state.id,
					this.state.title,
					this.state.raw,
					success => {
						if (success) alert('Successfully saved!');
						else alert('Save failed :(');
					}
				);
			} else {
				this.cloud.saveDialogue(this.state.title, this.state.raw, id => {
					alert('Successfully saved!');
					this.setState({ id: id });
					//this.setState({ redirect: true });
				});
			}
		}
	}

	render() {
    	return (
      		<div>
			    <h1>Write a dialogue!</h1>
				{!!this.state.id && 
					<p><strong>Paste this id into the <a href='https://melissaran.itch.io/coffeeshop-au' target='_blank' rel='noopener noreferrer'>game</a>: {this.state.id}</strong></p>}
				<p>Players will be able to play through the dialogues submitted here. 
					Hit "Check" before you save, otherwise it won't go through.
					Also, you can submit without logging in, but note that you won't 
					be able to edit your dialogue after leaving this page.</p>
				<p>Have fun!</p>
				<form className='TextForm' onSubmit={this.save}>
					<textarea cols='50' rows='10' 
						placeholder="Start writing! Everything you need to know is on the right." 
						value={this.state.raw}
						onChange={this.handleTextChange}
					/>
					<div className='solidButton' onClick={this.check}>Check</div>
					<input className="solidButton" type='submit' value='Save'/>
				</form>
      		</div>
    	);
  	}
}

export default withCloud(EditDialogue);