import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withCloud } from './Context';

import EditDialogue from './EditDialogue';
import ReferencePanel from './ReferencePanel';

class EditView extends Component {
	constructor(props) {
		super(props);
		this.state = !!props.location.state ? props.location.state : {};
		this.cloud = props.cloud;
	}

	redirect = () => this.setState({ redirect: true });

	render() {
		if (!!this.state.redirect)
			return <Redirect to='/'/>

		var form = [];
		if (!this.state.id) {
			form.push(<EditDialogue key={0}/>);
		} else {
			form = <EditDialogue prefilled={this.state}/>
		}

		return (
			<div className='Edit'>
				<div className='Form'>
					<div className='darkButton' id='back' onClick={this.redirect}>Back</div>
					{form}
				</div>
				<ReferencePanel />
			</div>
		);
  	}
}

export default withCloud(EditView);