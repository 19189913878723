const CHARACTERS = [
    'yuuri', 'victor', 'yurio', 'beka','deku','baku','todo','kiri','yosuke'
];
const LOCATIONS = {
    'coffeeshop': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6', 'door', 'window1','window2','window3',
        'table1','table2','table3','table4','table5','cashRegister','sink','counter1','counter2','counter3','counter4',
        'sign1','sign2','couch1','couch2','couch3','couch4','couch5'
    ],
    'coffeeshopEve': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6', 'door', 'window1','window2','window3',
        'table1','table2','table3','table4','table5','cashRegister','sink','counter1','counter2','counter3','counter4',
        'sign1','sign2','couch1','couch2','couch3','couch4','couch5'
    ],
    'coffeeshopNight': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6', 'door', 'window1','window2','window3',
        'table1','table2','table3','table4','table5','cashRegister','sink','counter1','counter2','counter3','counter4',
        'sign1','sign2','couch1','couch2','couch3','couch4','couch5'
    ],
    'townSquare': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6','SEAT7','SEAT8',
        'SEAT9','SEAT10','SEAT11','SEAT12','SEAT13','SEAT14',
        'fountain1','fountain2','fountain3','fountain4','fountain5',
        'fountain6','fountain7','fountain8','fountain9','fountain10',
        'stair1','stair2','stair3',
        'corner1','corner2','corner3','corner4','corner5','corner6',
        'entrance1','entrance2',
        'arch1','arch2','arch3','arch4',
        'behindArch1','behindArch2','behindArch3',
        'loc1','loc2','loc3','loc4','loc5','loc6','loc7','loc8','loc9','loc10'
    ],
    'townSquareEve': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6','SEAT7','SEAT8',
        'SEAT9','SEAT10','SEAT11','SEAT12','SEAT13','SEAT14',
        'fountain1','fountain2','fountain3','fountain4','fountain5',
        'fountain6','fountain7','fountain8','fountain9','fountain10',
        'stair1','stair2','stair3',
        'corner1','corner2','corner3','corner4','corner5','corner6',
        'entrance1','entrance2',
        'arch1','arch2','arch3','arch4',
        'behindArch1','behindArch2','behindArch3',
        'loc1','loc2','loc3','loc4','loc5','loc6','loc7','loc8','loc9','loc10'
    ],
    'townSquareNight': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6','SEAT7','SEAT8',
        'SEAT9','SEAT10','SEAT11','SEAT12','SEAT13','SEAT14',
        'fountain1','fountain2','fountain3','fountain4','fountain5',
        'fountain6','fountain7','fountain8','fountain9','fountain10',
        'stair1','stair2','stair3',
        'corner1','corner2','corner3','corner4','corner5','corner6',
        'entrance1','entrance2',
        'arch1','arch2','arch3','arch4',
        'behindArch1','behindArch2','behindArch3',
        'loc1','loc2','loc3','loc4','loc5','loc6','loc7','loc8','loc9','loc10'
    ],
    'karaoke': [
        'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4', 'SEAT5', 'SEAT6','SEAT7','onTable','screen'
    ],
    'bookStore': [
        'beanbag1', 'beanbag2', 'beanbag3', 'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4','SEAT5',
        'line1','line2','behindCounter','cubicles1','cubicles2','entry',
        'shelf1','shelf2','shelf3','shelf4','shelf5','shelf6', 'cornerEntrance','entranceThreshold'
    ],
    'bookStoreEve': [
        'beanbag1', 'beanbag2', 'beanbag3', 'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4','SEAT5',
        'line1','line2','behindCounter','cubicles1','cubicles2','entry',
        'shelf1','shelf2','shelf3','shelf4','shelf5','shelf6', 'cornerEntrance','entranceThreshold'
    ],
    'bookStoreNight': [
        'beanbag1', 'beanbag2', 'beanbag3', 'SEAT1', 'SEAT2', 'SEAT3', 'SEAT4','SEAT5',
        'line1','line2','behindCounter','cubicles1','cubicles2','entry',
        'shelf1','shelf2','shelf3','shelf4','shelf5','shelf6', 'cornerEntrance','entranceThreshold'
    ],
    'none': []
}
const ACTIONS = ['airGuitar', 'crossedArms','crouchGatherObjs','death1','excitedBounce','happySway',
    'kick1','looseGesture','nod','readyFlex','sadKickingAround','singing','talk1','thinking','tripAndFall',
    'wave1'];
const EXPRESSIONS = ['none', 'shock', 'bliss', 'sing', 'silence'];

export const validate = (raw) => {
    var lines = raw.split(/\n|\r|\r\n/);

    var dialogueName;
    var characters = [];
    var location;
    var hasFrame = false;

    if (!lines[lines.length - 1].includes('==='))
        throw new Error("Missing === at end");

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i].trim();
        if (line === '') continue;
        if (!line.includes('===') && !line.includes(':::')) {
            // either a character...
            if (characters.includes(line)) {
                if (!hasFrame) hasFrame = true;
                continue;
            }
            // ...or KEYWORD: value
            if (line.includes(':')) {
                var split = line.split(':');
                if (split.length < 2) 
                    throw new Error("Line " + (i+1) + ": value not specified");
                
                var keyword = split[0].trim();
                var value = split[1].trim();

                switch (keyword) {
                    case 'CHARACTERS':
                        if (characters.length > 0)
                            throw new Error("Line " + (i+1) + ": characters listed twice");
                        // update character list
                        var csplit = split[1].split(',');
                        for (var j = 0; j < csplit.length; j++) {
                            var c = csplit[j].trim();
                            // valid character?
                            if (!CHARACTERS.includes(c))
                                throw new Error("Line " + (i+1) + ": unrecognized character");
                            characters.push(c);
                        }
                        break;
                    case 'LOCATION':
                        location = value;
                        if (!LOCATIONS.hasOwnProperty(location))
                            throw new Error("Line " + (i+1) + ": unrecognized location");
                        break;
                    case 'ACTION':
                        if (!ACTIONS.includes(value))
                            throw new Error("Line " + (i+1) + ": unrecognized action");
                        break;
                    case 'LOC':
                        if (!location)
                            throw new Error("Line " + (i+1) + ": no location specified for this dialogue");
                        if (!LOCATIONS[location].includes(value))
                            throw new Error("Line " + (i+1) + ": unrecognized location");
                        break;
                    case 'EXPR':
                        if (!EXPRESSIONS.includes(value))
                            throw new Error("Line " + (i+1) + ": unrecognized expression");
                        break;
                    case 'LOOKAT':
                        // TODO: lookat can also be a location
                        if (!characters.includes(value) && !LOCATIONS[location].includes(value))
                            throw new Error("Line " + (i+1) + ": unlisted character used");
                        break;
                    case 'DIANAME':
                        if (dialogueName)
                            throw new Error("Line " + (i+1) + ": dialogue named twice");
                        dialogueName = value;
                        break;
                    case 'DIA':
                        break;
                    default:
                        throw new Error("Line " + (i+1) + ": unrecognized property");
                }
            } else throw new Error("Line " + (i+1) + ": unrecognized line");
        }
    }

    if (characters.length === 0)
        throw new Error("No characters specified!");

    if (!dialogueName)
        throw new Error("You need to name this dialogue!");

    if (!location)
        throw new Error("No location specified!");

    if (!hasFrame)
        throw new Error("You need at least 1 frame!");

    // TODO: more sophisticated checking, like, are lines in the right order?

    return dialogueName;
}