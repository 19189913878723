import React, { Component } from 'react';

export default class ExpressionReference extends Component {
    render() {
        return (
            <div className="Ref ac4">
                <p className="ac5"><span className="ac3">Expression list!</span></p>
                <hr/>
                <ul className="ac6 start">
                    <li className="ac2"><span className="ac0">shock</span></li>
                    <li className="ac2"><span className="ac0">bliss</span></li>
                    <li className="ac2"><span className="ac0">sing</span></li>
                    <li className="ac2"><span className="ac0">silence</span></li>
                </ul>
            </div>
        );
    }
}