import React, { Component } from 'react';

import './FormatReference.css';

export default class FormatReference extends Component {
    render() {
        return (
            <div className="Ref fc3">
                <p className="fc2"><span className="fc0 fc6">Formatting tutorial!</span></p>
                <hr/>
                <p className="fc2"><span>Every dialogue needs a header, where the </span><span className="fc0">name</span><span>, the </span><span className="fc0">characters involved</span><span>, and the </span><span className="fc0">scene it takes place in,</span><span className="fc5">&nbsp;are specified. Copy and paste this into the text field and modify it to suit your dialogue.</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc1">DIANAME: The Start of Something New</span></p>
                <p className="fc2"><span className="fc1">CHARACTERS:baku, victor, yosuke</span></p>
                <p className="fc2"><span className="fc1">LOCATION:karaoke</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc0 fc9 fc10">Locations and characters available to you can be shown by toggling the buttons above.</span></p>
                <p className="fc2"><span className="fc5">NOTE: you must specify all the characters who will be appearing in this dialogue!</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc5">Let&rsquo;s make a frame! The anatomy of a frame is:</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">yosuke</span></p>
                <p className="fc2"><span className="fc1">DIA: oh no i am falling</span></p>
                <p className="fc2"><span className="fc1">EXPR:sing</span></p>
                <p className="fc2"><span className="fc1">ACTION:tripAndFall</span></p>
                <p className="fc2"><span className="fc1">LOC:onTable</span></p>
                <p className="fc2"><span className="fc1">LOOKAT: baku</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc5">First, the character you are describing must be named. Spell it exactly as the character list spells it! Capitalization counts.</span></p>
                <ul className="fc12 start">
                    <li className="fc2 fc8"><span className="fc0">DIA</span><span>&nbsp;is short for dialogue.</span><span className="fc5">&nbsp;This is what the character is saying at the moment. Optional.</span></li>
                    <li className="fc2 fc8"><span className="fc0">EXPR</span><span>&nbsp;is short for expression</span><span className="fc0">.</span><span className="fc7">&nbsp;</span><span className="fc9">All expressions you can use can be shown by toggling the buttons above.</span><span className="fc7">.</span><span className="fc5">&nbsp;Optional. Expressions are maintained until you specify them again.</span></li>
                    <li className="fc2 fc8"><span className="fc0">ACTION</span><span>&nbsp;is the animation the character is playing this frame.</span><span className="fc9">&nbsp;All animations you can use can be shown by toggling the buttons above.</span><span className="fc5">&nbsp;Optional; Also only lasts one frame.</span></li>
                    <li className="fc2 fc8"><span className="fc0">LOC </span><span>is your character&rsquo;s location within the scene</span><span>.</span><span className="fc9">&nbsp;All sub-locations are numbered and named. You can find the map for your scene by toggling the buttons above. </span><span className="fc5">Optional; Only specify when you want your character to move somewhere new, or when you want a hidden character to be introduced to the scene.</span></li>
                    <li className="fc2 fc8"><span className="fc0">LOOKAT </span><span>is the name of the object the character will face. I</span><span className="fc5">t can be the name of another character (spelled right!) or the name of a location within the scene.</span></li>
                </ul>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span>Moving on, </span><span className="fc0">multiple characters</span><span>&nbsp;can be described in one frame, using the </span><span className="fc11">:::</span><span>&nbsp;separator. Everything within a frame happens </span><span className="fc0">concurrently</span><span className="fc5">. Players will be progressing through the frames like when you have to mash A lol</span></p>
                <p className="fc2"><span className="fc5">Here&rsquo;s an example dialogue:</span></p>
                <p className="fc2 fc4"><span className="fc5"></span></p>
                <p className="fc2"><span className="fc1">DIANAME: The Start of Something New</span></p>
                <p className="fc2"><span className="fc1">CHARACTERS:baku, victor, yosuke</span></p>
                <p className="fc2"><span className="fc1">LOCATION:karaoke</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">yosuke</span></p>
                <p className="fc2"><span className="fc1">DIA: I wanna be the very best</span></p>
                <p className="fc2"><span className="fc1">EXPR:sing</span></p>
                <p className="fc2"><span className="fc1">LOC:onTable</span></p>
                <p className="fc2"><span className="fc1">ACTION:singing</span></p>
                <p className="fc2"><span className="fc1">:::</span></p>
                <p className="fc2"><span className="fc1">victor</span></p>
                <p className="fc2"><span className="fc1">LOC:SEAT1</span></p>
                <p className="fc2"><span className="fc1">LOOKAT:yosuke</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">victor</span></p>
                <p className="fc2"><span className="fc1">DIA:that no one ever was</span></p>
                <p className="fc2"><span className="fc1">EXPR:sing</span></p>
                <p className="fc2"><span className="fc1">LOOKAT:screen</span></p>
                <p className="fc2"><span className="fc1">:::</span></p>
                <p className="fc2"><span className="fc1">yosuke</span></p>
                <p className="fc2"><span className="fc1">LOOKAT:victor</span></p>
                <p className="fc2"><span className="fc1">EXPR:bliss</span></p>
                <p className="fc2"><span className="fc1">ACTION:happySway</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">baku</span></p>
                <p className="fc2"><span className="fc1">LOC:SEAT2</span></p>
                <p className="fc2"><span className="fc1">DIA:I AM HERE</span></p>
                <p className="fc2"><span className="fc1">ACTION:kick1</span></p>
                <p className="fc2"><span className="fc1">:::</span></p>
                <p className="fc2"><span className="fc1">yosuke</span></p>
                <p className="fc2"><span className="fc1">EXPR:shock</span></p>
                <p className="fc2"><span className="fc1">ACTION:death1</span></p>
                <p className="fc2"><span className="fc1">:::</span></p>
                <p className="fc2"><span className="fc1">victor</span></p>
                <p className="fc2"><span className="fc1">EXPR:shock</span></p>
                <p className="fc2"><span className="fc1">LOOKAT:baku</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">victor</span></p>
                <p className="fc2"><span className="fc1">EXPR:silence</span></p>
                <p className="fc2"><span className="fc1">:::</span></p>
                <p className="fc2"><span className="fc1">baku</span></p>
                <p className="fc2"><span className="fc1">EXPR:silence</span></p>
                <p className="fc2"><span className="fc1">LOOKAT:yosuke</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">victor</span></p>
                <p className="fc2"><span className="fc1">ACTION:crossedArms</span></p>
                <p className="fc2"><span className="fc1">DIA: look what u done</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
                <p className="fc2"><span className="fc1">baku</span></p>
                <p className="fc2"><span className="fc1">ACTION:sadKickingAround</span></p>
                <p className="fc2"><span className="fc1">DIA: sowwy</span></p>
                <p className="fc2"><span className="fc1">===</span></p>
            </div>
        );
    }
}