import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { withCloud } from './Context';

const WorkCard = props => 
    <div className="WorkCard">
        <p>{props.work.title}</p>
        <p>ID: {props.work.id}</p>
        <div className="darkButton" onClick={() => props.edit(props.work)}>Edit</div>
        <div className="darkButton" onClick={() => props.delete(props.work)}>Delete</div>
    </div>
;

class ManageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            works: [],
            edit: null,
            toDelete: null,
            loading: true
        }
        this.cloud = props.cloud;
    }

    componentDidMount() {
        this.cloud.didLoginViaRedirect((success, _) => {
            if (success) this.getWorks();
            else this.setState({ loading: false });
        });
        if (!!this.cloud.currentUser) this.getWorks();
    }

    getWorks = () => {
        this.setState({ loading: false });
        this.cloud.getWorks(works => this.setState({ works: works }));
    }

    //login = () => this.cloud.login();

    redirectToWrite = () => this.setState({ redirectToWrite: true });
    redirectToEdit = data => this.setState({ edit: data });
    delete = data => this.setState({ toDelete: data });

    confirmDelete = () => {
        var id = this.state.toDelete.id;
        this.cloud.deleteDialogue(id);
        this.setState(prev => {
            var works = prev.works;
            works = works.filter(work => work.id !== id);
            return { works: works };
        });
        this.closeModal();
    }

    closeModal = () => this.setState({ toDelete: null });

	render() {
        if (!!this.state.redirectToWrite)
            return <Redirect to='/write'/>

        if (!!this.state.edit)
            return <Redirect to={{
                pathname: '/write', 
                state: this.state.edit 
            }}/>

        var works = [];
        for (var i = 0; i < this.state.works.length; i++) {
            var work = this.state.works[i];
            works.push(
                <WorkCard 
                    key={i}
                    work={work}
                    edit={this.redirectToEdit}
                    delete={this.delete}
                />
            );
        }

    	return (
      		<div className="Manage">
                <div className="header">The Coffeeshop AU</div>
                {!this.cloud.currentUser && 
                    <div className="darkButton" id='login' onClick={this.cloud.login}>Log in</div>}
                {!!this.cloud.currentUser && <h1>Your works</h1>}
                {this.state.loading && <div id='loading'>Loading...</div>}
                {works}
                <div className="darkButton" id='write' onClick={this.redirectToWrite}>Write something!</div>
                <div id='play'>
                    <a href='https://melissaran.itch.io/coffeeshop-au' target='_blank' rel='noopener noreferrer'>Play the game!</a>
                </div>
                <Modal 
                    className="Modal"
                    isOpen={!!this.state.toDelete}
                    shouldCloseOnOverlayClick={true}
                >
                    {!!this.state.toDelete && 
                        <p>Are you sure you want to delete "{this.state.toDelete.title}"?</p>}
                    <div className="darkButton" onClick={this.confirmDelete}>Yes</div>
                    <div className="darkButton" onClick={this.closeModal}>No</div>
                </Modal>
      		</div>
    	);
  	}
}

export default withCloud(ManageView);