import React, { Component } from 'react';

import FormatReference from './Reference/FormatReference';
import CharacterReference from './Reference/CharacterReference';
import ActionReference from './Reference/ActionReference';
import ExpressionReference from './Reference/ExpressionReference';

import CoffeeshopMap from './Reference/CoffeeshopMap';
import TownSquareMap from './Reference/TownSquareMap';
import BookstoreMap from './Reference/BookstoreMap';
import KaraokeMap from './Reference/KaraokeMap';

export default class ReferencePanel extends Component {
    constructor(props) {
        super(props);
        this.state = { show: '' }
    }

    toggle = (keyword) => this.setState({ show: keyword });

    render() {
        return (
            <div className="Doc">
                <span className="lightButton" onClick={() => this.toggle('Format')}>
                    format help
                </span>
                <span className="lightButton" onClick={() => this.toggle('Characters')}>
                    character list
                </span>
                <span className="lightButton" onClick={() => this.toggle('Actions')}>
                    action list
                </span>
                <span className="lightButton" onClick={() => this.toggle('Expressions')}>
                    expression list
                </span>
                <span className="lightButton" onClick={() => this.toggle('Coffeeshop')}>
                    coffeeshop map
                </span>
                <span className="lightButton" onClick={() => this.toggle('Square')}>
                    town square map
                </span>
                <span className="lightButton" onClick={() => this.toggle('Bookstore')}>
                    bookstore map
                </span>
                <span className="lightButton" onClick={() => this.toggle('Karaoke')}>
                    karaoke map
                </span>
                {this.state.show === 'Format' && <FormatReference />}
                {this.state.show === 'Characters' && <CharacterReference />}
                {this.state.show === 'Actions' && <ActionReference />}
                {this.state.show === 'Expressions' && <ExpressionReference />}
                {this.state.show === 'Coffeeshop' && <CoffeeshopMap />}
                {this.state.show === 'Square' && <TownSquareMap />}
                {this.state.show === 'Bookstore' && <BookstoreMap />}
                {this.state.show === 'Karaoke' && <KaraokeMap />}
            </div>
        );
    }
}