import React, { Component } from 'react';

import './CharacterReference.css';

export default class CharacterReference extends Component {
    render() {
        return (
            <div className="Ref cc8">
                <p className="cc7"><span className="cc6">Character list!</span></p>
                <hr/>
                <ul className="cc2 lst-kix_ygifuf6umdek-0 start">
                    <li className="cc3"><span className="cc5">Yuri on Ice</span></li>
                </ul>
                <ul className="cc2 lst-kix_ygifuf6umdek-1 start">
                    <li className="cc1"><span className="c0">yuuri</span></li>
                    <li className="cc1"><span className="c0">victor</span></li>
                    <li className="cc1"><span className="c0">yurio</span></li>
                    <li className="cc1"><span className="c0">beka</span></li>
                </ul>
                <ul className="cc2 lst-kix_ygifuf6umdek-0">
                    <li className="cc3"><span className="cc5">My Hero Academia</span></li>
                </ul>
                <ul className="cc2 lst-kix_ygifuf6umdek-1 start">
                    <li className="cc1"><span className="c0">deku</span></li>
                    <li className="cc1"><span className="c0">baku</span></li>
                    <li className="cc1"><span className="c0">todo</span></li>
                    <li className="cc1"><span className="c0">kiri</span></li>
                </ul>
                <ul className="cc2 lst-kix_ygifuf6umdek-0">
                    <li className="cc3"><span className="cc5">Persona 4</span></li>
                </ul>
                <ul className="cc2 lst-kix_ygifuf6umdek-1 start">
                    <li className="cc1"><span className="c0">yosuke</span></li>
                </ul>
            </div>
        );
    }
}