import React, { Component } from 'react';

import './ActionReference.css';

export default class ActionReference extends Component {
    render() {
        return (
            <div className="Ref ac4">
                <p className="ac5"><span className="ac3">Action (animation) list!</span></p>
                <hr/>
                <ul className="ac6 start">
                    <li className="ac2"><span className="ac0">airGuitar</span></li>
                    <li className="ac2"><span className="ac0">crossedArms</span></li>
                    <li className="ac2"><span className="ac0">crouchGatherObjs</span></li>
                    <li className="ac2"><span className="ac0">death1</span></li>
                    <li className="ac2"><span className="ac0">excitedBounce</span></li>
                    <li className="ac2"><span className="ac0">happySway</span></li>
                    <li className="ac2"><span className="ac0">kick1</span></li>
                    <li className="ac2"><span className="ac0">looseGesture</span></li>
                    <li className="ac2"><span className="ac0">nod</span></li>
                    <li className="ac2"><span className="ac0">readyFlex</span></li>
                    <li className="ac2"><span className="ac0">sadKickingAround</span></li>
                    <li className="ac2"><span className="ac0">searchPockets</span></li>
                    <li className="ac2"><span className="ac0">singing</span></li>
                    <li className="ac2"><span className="ac0">talk1</span></li>
                    <li className="ac2"><span className="ac0">thinking</span></li>
                    <li className="ac2"><span className="ac0">tripAndFall</span></li>
                    <li className="ac2"><span>wave1</span></li>
                </ul>
            </div>
        );
    }
}