import React, { Component } from 'react';

import './TownSquareMap.css';

export default class TownSquareMap extends Component {
    render() {
        return (
            <div className="Ref tsc5">
                <img src="https://i.imgur.com/QkSsK2p.png" alt="town square map"/>
                <p className="tsc3"><span>Reference as </span><span className="tsc6">townSquare</span><span>&nbsp;/ </span><span className="tsc6">townSquareNight</span><span>&nbsp;(nighttime) / </span><span className="tsc6">townSquareEve</span><span className="tsc0">&nbsp;(evening).</span></p>
                <p className="tsc2"><span className="tsc0"></span></p>
                <p className="tsc3"><span className="tsc0">BLACK:</span></p>
                <ol className="tsc4 lst-kix_o6a5domy1xrm-0 start" start="1">
                    <li className="tsc1"><span className="tsc0">SEAT1</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT2</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT3</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT4</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT5</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT6</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT7</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT8</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT9</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT10</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT11</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT12</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT13</span></li>
                    <li className="tsc1"><span className="tsc0">SEAT14</span></li>
                    <li className="tsc1"><span className="tsc0">fountain1</span></li>
                    <li className="tsc1"><span className="tsc0">fountain2</span></li>
                    <li className="tsc1"><span className="tsc0">fountain3</span></li>
                    <li className="tsc1"><span className="tsc0">fountain4</span></li>
                    <li className="tsc1"><span className="tsc0">fountain5</span></li>
                    <li className="tsc1"><span className="tsc0">fountain6</span></li>
                    <li className="tsc1"><span className="tsc0">fountain7</span></li>
                    <li className="tsc1"><span className="tsc0">fountain8</span></li>
                    <li className="tsc1"><span className="tsc0">fountain9</span></li>
                    <li className="tsc1"><span className="tsc0">fountain10</span></li>
                    <li className="tsc1"><span className="tsc0">stair1</span></li>
                    <li className="tsc1"><span className="tsc0">stair2</span></li>
                    <li className="tsc1"><span className="tsc0">stair3</span></li>
                    <li className="tsc1"><span className="tsc0">corner1</span></li>
                    <li className="tsc1"><span className="tsc0">corner2</span></li>
                    <li className="tsc1"><span className="tsc0">corner3</span></li>
                    <li className="tsc1"><span className="tsc0">corner4</span></li>
                    <li className="tsc1"><span className="tsc0">corner5</span></li>
                    <li className="tsc1"><span className="tsc0">corner6</span></li>
                    <li className="tsc1"><span className="tsc0">entrance1</span></li>
                    <li className="tsc1"><span className="tsc0">entrance2</span></li>
                    <li className="tsc1"><span className="tsc0">arch1</span></li>
                    <li className="tsc1"><span className="tsc0">arch2</span></li>
                    <li className="tsc1"><span className="tsc0">arch3</span></li>
                    <li className="tsc1"><span className="tsc0">arch4</span></li>
                    <li className="tsc1"><span className="tsc0">behindArch1</span></li>
                    <li className="tsc1"><span className="tsc0">behindArch2</span></li>
                    <li className="tsc1"><span className="tsc0">behindArch3</span></li>
                </ol>
                <p className="tsc2"><span className="tsc0"></span></p>
                <p className="tsc3"><span className="tsc0">BLUE:</span></p>
                <ol className="tsc4 lst-kix_wadu298wklwh-0 start" start="1">
                    <li className="tsc1"><span className="tsc0">loc1</span></li>
                    <li className="tsc1"><span className="tsc0">loc2</span></li>
                    <li className="tsc1"><span className="tsc0">loc3</span></li>
                    <li className="tsc1"><span className="tsc0">loc4</span></li>
                    <li className="tsc1"><span className="tsc0">loc5</span></li>
                    <li className="tsc1"><span className="tsc0">loc6</span></li>
                    <li className="tsc1"><span className="tsc0">loc7</span></li>
                    <li className="tsc1"><span className="tsc0">loc8</span></li>
                    <li className="tsc1"><span className="tsc0">loc9</span></li>
                    <li className="tsc1"><span>lotsc10</span></li>
                </ol>
            </div>
        );
    }
}