import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import EditView from './EditView';
import ManageView from './ManageView';

import './App.css';

export default class App extends Component {
	render() {
    	return (
      		<div className="App">
				<Switch>
					<Route exact path='/' component={ManageView}/>
					<Route exact path='/write' component={EditView}/>
				</Switch>
      		</div>
    	);
  	}
}
