import React, { Component } from 'react';

import './BookstoreMap.css';

export default class BookstoreMap extends Component {
    render() {
        return (
            <div className="Ref bc5">
                <img src="https://i.imgur.com/9ZBpKa6.png" alt="bookstore map"/>
                <p className="bc1"><span>Reference as </span><span className="bc6">bookStore</span><span>&nbsp;/ </span><span className="bc6">bookStoreNight</span><span>&nbsp;(nighttime) / </span><span className="bc6">bookStoreEve</span><span className="bc0">&nbsp;(evening).</span></p>
                <p className="bc1 bc7"><span className="bc0"></span></p>
                <ol className="bc4 lst-kix_wadu298wklwh-0 start" start="1">
                    <li className="bc1 bc2"><span className="bc0">beanbag1</span></li>
                    <li className="bc1 bc2"><span className="bc0">beanbag2</span></li>
                    <li className="bc1 bc2"><span className="bc0">beanbag3</span></li>
                    <li className="bc1 bc2"><span className="bc0">SEAT1</span></li>
                    <li className="bc1 bc2"><span className="bc0">SEAT2</span></li>
                    <li className="bc1 bc2"><span className="bc0">SEAT3</span></li>
                    <li className="bc1 bc2"><span className="bc0">SEAT4</span></li>
                    <li className="bc1 bc2"><span className="bc0">SEAT8</span></li>
                    <li className="bc1 bc2"><span className="bc0">line1</span></li>
                    <li className="bc1 bc2"><span className="bc0">line2</span></li>
                    <li className="bc1 bc2"><span className="bc0">behindCounter</span></li>
                    <li className="bc1 bc2"><span className="bc0">cubicles1</span></li>
                    <li className="bc1 bc2"><span className="bc0">cubicles2</span></li>
                    <li className="bc1 bc2"><span className="bc0">entry</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf1</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf2</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf3</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf4</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf5</span></li>
                    <li className="bc1 bc2"><span className="bc0">shelf6</span></li>
                    <li className="bc1 bc2"><span className="bc0">cornerEntrance</span></li>
                    <li className="bc1 bc2"><span>entranceThreshold</span></li>
                </ol>
            </div>
        );
    }
}